
// @ts-nocheck
// OG IMPORTS
import { EventBus, Events } from '@events'
import * as VCard from 'vuetify/es5/components/VCard'
import ItemAuthorItemContainer from '@components/itemAuthor/ItemAuthorItemContainer'
import ItemAuthorActions from '@components/itemAuthor/ItemAuthorActions'
import ItemAuthorDiscardDialog from '@components/itemAuthor/ItemAuthorDiscardDialog'
import ItemAuthorSaveSpinner from '@components/itemAuthor/ItemAuthorSaveSpinner'
import IbxButton from '@components/ibx/IbxButton'
import IbxConfirmationModal from '@components/itemAuthor/IbxConfirmationModal'
import { mapActions, mapGetters } from 'vuex'
import { AsmtMixin } from '@mixins'
import { CONST } from '@constants'

// NEW IMPORTS
import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { StateAuth, StateAuthorItems, StateAuthorPassages, StoreAction } from '@/helpers/state'
import { ItemAuthor } from '@/components/ibx/base/Item'
import { ItemAuthoringMode, ItemAuthorSaveAction, MenuTab } from '@/state/modules/authorItems'
import { SavingMessageMixin } from '@mixins'
import IbxSavingMessage from '@components/ibx/IbxSavingMessage'
import IBXService from '@services/ibx/IBXService'
import { Id } from '@/components/ibx/base/Types'
import _ from 'lodash'
import { checkForMultiLevelRubric } from '@helpers/itemHelpers'

type ItemSaveResponse = {
  success: boolean
  itemId: Id
  itemRevId: Id
  error: any
  action: string
}

/**
 * Text pluralizer heler
 * @param text text to pluralize
 * @param count contextual count of items
 */
const pluralizer = (text: string, count: number): string => {
  return count > 1 ? `${text}s` : text
}

/**
 * Save action label
 */
const actionLabel = (action: string): string => {
  switch (action) {
    case ItemAuthorSaveAction.DRAFT:
      return 'saved'
    case ItemAuthorSaveAction.PUBLISH:
    case ItemAuthorSaveAction.PUBLISH_NEW:
      return 'published'
    default:
      return 'discarded'
  }
}

@Component({
  components: {
    ...VCard,
    IbxButton,
    ItemAuthorItemContainer,
    ItemAuthorActions,
    ItemAuthorDiscardDialog,
    ItemAuthorSaveSpinner,
    IbxSavingMessage,
    IbxConfirmationModal,
  },
  computed: {
    ...mapGetters('auth', {
      authPieToken: 'pieToken',
    }),
    ...mapGetters('itemAuthor', ['updated', 'showPassCheckbox', 'itemSavingFailed', 'saveInProgress', 'banksFetched']),
    ...mapGetters('config', ['helpUrl']),
    ...mapGetters('browseFilters', {
      browseFilters: 'filters',
    }),
  },
  methods: {
    ...mapActions('itemAuthor', [
      'fetchBanks',
      'setSelectedBanks',
      'setUpdated',
      'setItemCount',
      'setShowPassCheckbox',
      'setSavedItemCount',
      'resetSavedItemCount',
      'resetErrorItemCount',
      'setItemSavingFailed',
    ]),
    ...mapActions('auth', {
      authInit: 'init',
    }),
    ...mapActions('browseFilters', {
      browseFetchFilters: 'fetchFilters',
    }),
  },
})
export default class ItemAuthorDialog extends Mixins(
  Vue,
  AsmtMixin,
  SavingMessageMixin,
  StateAuth,
  StateAuthorItems,
  StateAuthorPassages
) {
  @Prop({ default: false }) active: boolean

  private itemAuthorItemContainers = []
  private itemCount = 0
  private duration = 200
  private currentTab = null
  private containerKey = 0
  private showDoneMenu = false
  private showDiscardDialog = false
  private confirmAction: string = null
  private ready: boolean = false
  private savingOverlay: boolean = false

  /**
   * Get item if revision mode
   */
  get item(): ItemAuthor {
    return this.authorGetEditItem
  }

  get isEditMode(): boolean {
    return this.authorMode == ItemAuthoringMode.EDIT
  }

  get updatedDate(): string {
    return this.item ? this.formatDate(this.item.updatedAt) : ''
  }

  get title(): string {
    return this.isEditMode ? `Item ID #${this?.item?.itemId}` : 'Item Creation'
  }
  get getAsmtTitle(): string {
    const maxLen = 45
    return this.asmtTitle.length > maxLen ? `${this.asmtTitle.substring(0, maxLen)}...` : this.asmtTitle
  }

  get totalItemsLabel(): string {
    return `${this.authorItems.length} Item${this.authorItems.length > 1 ? 's' : ''}`
  }

  get lastItem(): ItemAuthor {
    return [...this.authorItems].pop()
  }

  /**
   * Show inherit passage if last item passage affiliation
   */
  get showInheritPassage(): boolean {
    return Boolean(!this.isEditMode && this.lastItem?.passageAff?.passageRevId)
  }

  /**
   * Passage editing flag
   */
  get isPassageEditing(): boolean {
    return this.authorMenuTab == MenuTab.PASSAGE && this.authorMenuTabView == 'edit'
  }

  /**
   * Footer classes based on authoring states
   */
  get footerClass(): string[] {
    const classes = []
    if (this.isPassageEditing) classes.push('item-author-dialog__footer--passage-editing')
    if (this.savingOverlay) classes.push('item-author-dialog__footer--saving')
    return classes
  }

  /**
   * Done button classes
   */
  get doneClass(): string[] {
    return this.isPassageEditing ? ['item-author-done--disabled'] : []
  }

  get modalBodyClass(): string[] {
    return this.isPassageEditing ? ['modal-body--no-scroll'] : []
  }

  get inheritPassage(): boolean {
    return this.authorInheritPassage
  }

  set inheritPassage(v: boolean) {
    this.authorSetInheritPassage(v)
  }

  /**
   * Change saving to changed
   * based on store state
   */
  @Watch('authorHasChange')
  onAuthorChange(v) {
    this.setWindowTrap(v)
  }

  /**
   * Change saving message
   * based on store state
   */
  @Watch('authorIsSaving')
  onAuthorIsSaving(v) {
    if (v) {
      this.smSaving()
    } else if (this.authorHasErrors) {
      this.smClear()
    } else {
      this.smSaved()
    }
  }

  /**
   * Initialize Item Authoring
   * Fetch and await for required data if not hydrated in state
   */
  async onInitDialog() {
    this.authorSetActive(true)

    // fetch required data
    try {
      await this.getPieToken()
      await this.getFilters()
      await this.getBanks()
    } catch (error) {
      EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error })
    }

    if (this.isEditMode) {
      const itemMeta = this.item?.meta
      if (itemMeta && itemMeta.bank && itemMeta.bank.items) {
        this.setSelectedBanks(itemMeta.bank.items.map((o) => o.id))
      }
    }
  }

  /**
   * Initialize authentication (PIE TOKEN) if need
   * @returns Promime<any>
   */
  async getPieToken() {
    return !this.authPieToken ? this.authInit() : Promise.resolve()
  }

  /**
   * Hydrate browse filters state if empty.
   * Filter data is used when adding tags to items.
   * @returns Promime<any>
   */
  async getFilters() {
    return !this.browseFilters.length ? this.browseFetchFilters() : Promise.resolve()
  }

  /**
   * Hydrate banks state if needed
   * Filter data is used when adding tags to items.
   * @returns Promime<any>
   */
  async getBanks() {
    return !this.banksFetched ? this.fetchBanks() : Promise.resolve()
  }

  formatDate(value) {
    var d = new Date(Date.parse(value))
    return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
  }

  /**
   * Close dialog, emit close event
   * @param action close action taken
   */
  closeDialog(action?: string = '') {
    this.$emit('close', { action })
    this.savingOverlay = false
  }

  addNewItem() {
    // create item
    const count = 1
    const passageAff = this.lastItem?.passageAff
    const passage = this.inheritPassage ? this.authorPassageGetPassage(passageAff.passageRevId) : null
    this.authorCreateItems({ count, options: { passage } })
    this.onNewItemCreated({ count, action: 'add' })
  }

  /**
   * Duplicate item handler
   * @param options item options (data to duplicate in new item)
   */
  onDuplicateItem({ count = 1, options = {} }: { count: number; options: any }) {
    this.authorCreateItems({ count, options: _.cloneDeep(options) })
    this.onNewItemCreated({ count, action: 'duplicate' })
  }

  /**
   * Delete item handler
   */
  async onDeleteItem({ item }: { item: ItemAuthor }) {
    if (item?.itemId) {
      // remove item and scroll to previous item
      const itemId = item.itemId
      const containers = this.$refs.itemContainer || []
      const index = (containers as any).findIndex((o: any) => o.item?.itemId == itemId)
      const previousContainer = containers[index - 1]
      this.authorRemoveItems([itemId])
      previousContainer?.$el?.scrollIntoView({ behavior: 'smooth' })

      // delete item if item has been saved
      if (!item.isNew) {
        try {
          await IBXService.itemDelete(itemId)
        } catch {
          EventBus.$emit(Events.SNACKBAR, {
            type: 'error',
            text: 'Error removing item',
            timeout: 10000,
          })
        }
      }

      // set saving message for item & remove window trap
      this.authorSetSaving({
        Id: itemId,
        saving: false,
      })
      this.setWindowTrap(false)

      // emit success message
      EventBus.$emit(Events.SNACKBAR, {
        type: 'success',
        text: 'Item removed',
        timeout: 10000,
      })
    }
  }

  /**
   * On new item or duplicate item
   * check for save and scroll to new item
   * @param action add or duplicate
   * @param count items added/duplicated
   */
  onNewItemCreated({ action, count }: { action: string; count: number }) {
    this.$nextTick(() => {
      // mark duplicates as changed for automatic save
      const containers = this.$refs.itemContainer || []
      containers.forEach((item: ItemAuthorItemContainer, index) => {
        if (item.isNew && action == 'duplicate') {
          item.changeItem([
            {
              itemId: item.itemId,
              key: 'itemId',
              value: item.itemId,
            },
          ])
        }
      })

      // save items with changes except for duplicated items (duplcates saved later)
      if (this.authorHasChange && action != 'duplicate') this.doSaveAction({ action: CONST.DRAFT, done: false })

      // scroll to item newly created item
      this.scrollToNextContainer()
      EventBus.$emit(Events.SNACKBAR, {
        type: 'success',
        text: action == 'add' ? 'Item Added' : 'Item Duplicated',
        timeout: 10000,
      })
    })
  }

  scrollToNextContainer() {
    const containers = this.$refs.itemContainer
    const last = containers[(containers as any).length - 1]
    last.$el.scrollIntoView({ behavior: 'smooth' })
  }

  scrollToContainerIndex(index: number) {
    const $container = this.$refs?.itemContainer?.[index]?.$el
    $container?.scrollIntoView()
  }

  getCurrentMenuState() {
    this.currentTab = this.$refs.itemContainer[this.itemCount - 1].newTab
  }

  updateItemTitles() {
    for (var i = 0; i < this.itemAuthorItemContainers.length; i++) {
      this.itemAuthorItemContainers[i].index = i
      this.itemAuthorItemContainers[i].questionNum = i + 1 // for Q label
      this.$set(this.itemAuthorItemContainers, i, this.itemAuthorItemContainers[i])
    }
  }

  onHelpClick() {
    window.open(`${this.helpUrl}/categories/30099387656731-Assessment-Types`)
  }

  /**
   * Close UI click handler
   * Confirm close if needed.
   */
  onCloseClick() {
    if (this.authorHasChange && !this.authorAutosave) {
      this.onMainMenuAction({
        action: CONST.DISCARD_CHANGE,
        confirm: true,
      })
    } else if (this.authorHasChange && this.authorAutosave) {
      this.showDoneMenu = false
      this.savingOverlay = true
      this.doSaveAction({
        action: CONST.DRAFT,
        done: true,
      })
    } else {
      this.doSaveAction({
        action: '',
        done: true,
      })
      this.closeDialog()
    }
  }

  /**
   * Author Items save action/close (from Done menu)
   * @param action save action
   * @param confirm confirm prior to taking action
   */
  async onMainMenuAction({ action, confirm }: { action: string; confirm?: boolean }) {
    if (confirm) {
      this.confirmAction = action
      this.showDiscardDialog = true
    } else if (action == 'publish' || action == 'publish-new') {
      this.asmtClearValidationErrors()
      if ((await this.validateFields()) && !this.checkIfStandardMissing()) {
        if (checkForMultiLevelRubric(this.authorItems)) {
          EventBus.$emit(Events.CONFIRMATION_SHOW, {
            data: action,
            title: 'Publish Item',
            message:
              "This type of item can't be edited after it is published. Are you sure you are ready to publish it now?",
            cancelBtnLabel: 'Not Right Now',
            confirmBtnLabel: 'Yes, Publish',
          })
        } else {
          this.confirmAndSave(action)
        }
      }
    } else {
      this.confirmAndSave(action)
    }
  }

  validateFields() {
    return new Promise((resolve) => {
      let pieAuthor = [...document.getElementsByTagName('pie-author')]
      let promises = pieAuthor.map((v) => {
        return new Promise((resolve) => resolve(v.validateModels()))
      })
      let results = Promise.all(promises)
      // Promise returns { hasErrors: true} if there is an error else { hasErrors: false }
      results
        .then((resp) => {
          let errorIndex = this.checkIfError(resp)
          if (errorIndex > -1) {
            this.showDoneMenu = false
            let el = document.getElementsByTagName('pie-author')[errorIndex]
            el.scrollIntoView({ behavior: 'smooth' })
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              text: 'Failed to Save',
              subtext: 'Please update item to meet requirements',
              timeout: 4000,
            })
            resolve(false)
          } else {
            resolve(true)
          }
        })
        .catch((error) => {
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: 'Failed to Validate items',
            subtext: 'Please refresh the page',
            timeout: 4000,
          })
        })
    })
  }
  checkIfError(response) {
    let errorIndex = -1
    for (const [index, elem] of response.entries()) {
      if (elem.hasErrors) {
        errorIndex = index
        break
      }
    }
    return errorIndex
  }
  confirmAndSave(action) {
    this.showDoneMenu = false
    this.savingOverlay = true
    this.doSaveAction({ action, done: Boolean(action) })
  }

  showNotificationOnError(error, index = null) {
    this.showDoneMenu = false

    if (index !== null) {
      let el = document.getElementsByTagName('pie-author')[index]
      el.scrollIntoView({ behavior: 'smooth' })
    }

    EventBus.$emit(Events.ERROR, {
      type: CONST.ERROR,
      error: error.error,
      text: error.text,
      subtext: error.subText,
      timeout: 4000,
    })
  }

  checkIfStandardMissing() {
    let isError = false
    const items: any[] = (this.$refs?.itemContainer as any[]) || []
    for (let i = 0; i <= items.length - 1; i++) {
      let itemMeta = items[i].item?.meta
      if (!itemMeta?.standard?.items.length && !items[i]?.item['hasNoStandard']) {
        isError = true
        this.showNotificationOnError(
          { text: 'Failed to Publish', subText: 'Please add standards to all items and try publishing again.' },
          i
        )
        break
      }
    }
    return isError
  }
  /**
   * Trigger save action for each item...called in chunks/batches
   * @param action item save action to take if any
   * @param done autosave done when all actions complete
   */
  async doSaveAction({ action, done = false }: { action: string; done: boolean }) {
    this.authorSetSaveAction(action)
    const items: any[] = (this.$refs?.itemContainer as any[]) || []
    const chunks = _.chunk(items, 3)

    // sequence
    async function* saveSequence() {
      for (let i = 0; i <= chunks.length - 1; i++) {
        const chunk: ItemAuthor[] = chunks[i]
        const promises = chunk.map((item: ItemAuthor) => item.commitAction(action))
        const results = await Promise.allSettled(promises)
        const responses = results.map((o: any, j: number) => {
          const item: ItemAuthor = chunk[j]
          return o.status == 'fulfilled'
            ? Object.assign({}, o.value, { action })
            : Object.assign({
                error: o.reason,
                itemId: item.itemId,
                itemRevId: item.itemRevId,
                action,
              })
        })

        yield responses
      }
    }

    // async iterator
    let generator = saveSequence()
    const responses: ItemSaveResponse[] = []
    for await (let chunkResponses of generator) {
      chunkResponses.forEach((response) => {
        if (response.error) console.warn(response.error)
        responses.push({
          itemId: response?.item?.itemId,
          itemRevId: response?.item?.itemRevId,
          success: !response.error,
          error: response.error,
          action: response.action,
        })
      })
    }

    // if done authoring, notify and close
    if (done) this.closeDialog(action)
  }

  /**
   * Discard dialog close handler
   */
  onDiscardDialogClose() {
    this.showDiscardDialog = false
    this.confirmAction = null
  }

  /**
   * Discard dialog confirmation handler
   */
  onDiscardDialogConfirm() {
    this.onDiscardDialogClose()
    this.closeDialog()
  }

  /**
   * On before widow unload trap
   */
  onWindowBeforeUnload(e) {
    e.preventDefault()
    e.returnValue = ''
  }

  /**
   * Set window before unload trap
   */
  setWindowTrap(trap: boolean) {
    if (trap) {
      window.addEventListener('beforeunload', this.onWindowBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
    }
  }
  handleConfirmation(value) {
    this.confirmAndSave(value.data)
  }
  /**
   * Vue hook
   */
  created() {
    this.saveContext = 'authoring'
    this.smDone() // create saving state
  }

  /**
   * Vue hook
   */
  mounted() {
    this.onInitDialog()
  }

  /**
   * Vue hook
   */
  beforeDestroy() {
    this.authorSetActive(false)
    this.setWindowTrap(false)
  }
}

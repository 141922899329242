
// @ts-nocheck
// ORIGINAL IMPORTS
import * as VChip from 'vuetify/es5/components/VChip'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import IBXService from '@services/ibx/IBXService'
import PIEService from '@services/PIEService'
import { mapActions, mapGetters } from 'vuex'
import { Events, EventBus } from '@events'
import { CONST } from '@constants'
import { AppMixin, AsmtMixin } from '@mixins'
import _ from 'lodash'
import { authorSettings, ibxItemTypes, defaultRubricConfig } from '@components/itemAuthor/configs/itemConfigs.js'

// NEW IMPORTS
import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { StateAuthorItems, StateAuthorPassages, StateAuth, StateItemConfigs, StateFeatures } from '@/helpers/state'
import { Id, Bank, ItemConfigs, ItemTypes, ItemConfig, ItemType } from '@/components/ibx/base/Types'
import { ItemAuthor } from '@/components/ibx/base/Item'
import ItemAuthorBase from '@components/itemAuthor/base/ItemAuthorBase'
import { ItemAuthoringMode } from '@/state/modules/authorItems'
import { FLAG } from '@constants'
import ItemAuthorConfirmDialog from '@components/itemAuthor/AuthorItemConfirmDialog'
/**
 * Player Events
 */
enum PlayerEventsType {
  CONTENT_LOADED = 'contentLoaded',
  MODEL_LOAED = 'modelLoaded',
  MODEL_UPDATED = 'modelUpdated',
}
type PlayerEvent = {
  type?: string
}

@Component({
  components: {
    ...VTooltip,
    ItemAuthorConfirmDialog,
  },
  methods: {
    ...mapActions('itemAuthor', ['setLoaded']),
  },
})
export default class ItemAuthorPlayer extends Mixins(
  Vue,
  ItemAuthorBase,
  StateAuthorItems,
  StateAuthorPassages,
  StateAuth,
  StateItemConfigs,
  StateFeatures
) {
  @Prop({ default: 0 }) index: number
  data() {
    return {
      oldRubric: false,
      itemData: {},
    }
  }
  private ready: boolean = false
  private loading: boolean = false
  private player: any = null
  private configs: ItemConfigs
  private types: ItemTypes
  private itemExitConfirmAction: boolean = false

  get ibxItemTypes(): any {
    return ibxItemTypes
  }

  get isEditMode(): boolean {
    return this.authorMode == ItemAuthoringMode.EDIT
  }

  get isNew(): boolean {
    return this.item?.isNew || false
  }
  get isDraft(): boolean {
    return this.item?.isDraft
  }
  get itemType(): string {
    return this.item.itemType
  }
  get itemTypeObject(): ItemType {
    return this.types.get(this.itemType) || this.types.default
  }
  get itemTypes(): ItemType[] {
    return this.types.getAll().filter((o: ItemType) => this.featureSupportedItemTypes[o.id])
  }
  get contenConfig(): any {
    return this.item?.contentConfig
  }
  get configSettings(): any {
    let configSettings = _.cloneDeep(this.item.configSettings)
    if (this.supportsRubric && this.supportsMultiLevelRubric) {
      configSettings['@pie-element/extended-text-entry']['withRubric'] = {
        settings: true,
        label: 'Add Rubric',
        forceEnabled: true,
      }
    }
    return configSettings
  }

  get itemTypeDisabled(): boolean {
    return Boolean(this.isEditMode || this.authorSaving?.length || this.item?.published || this.loading)
  }

  get playerContainerClass(): string {
    return this.loading ? 'pie-api-author-container--loading' : ''
  }

  get supportsRubric(): boolean {
    return ['constructed_response', 'drawing_response'].includes(this.itemType)
  }

  get supportsMultiLevelRubric(): boolean {
    const supported = ['constructed_response']
    return supported.includes(this.itemType) && this.featureFlag(FLAG.MULTI_LEVEL_RUBRICS)
  }
  get defaultRubricConfig(): any {
    return defaultRubricConfig
  }
  /**
   * Initiazilze component
   */
  init() {
    this.configs = ItemConfigs.getInstance()
    this.types = ItemTypes.getInstance()
    this.initPlayer()
    this.setLoaded(true) // old
  }

  /**
   * Initialize player then load item
   */
  async initPlayer() {
    this.player = this.$refs.player
    try {
      this.player.token = this.authPieToken
      this.player.host = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
      this.player.lockVersions = false

      this.player.addEventListener(PlayerEventsType.CONTENT_LOADED, this.onPlayerEvent)
      this.player.addEventListener(PlayerEventsType.MODEL_LOAED, this.onPlayerEvent)
      this.player.addEventListener(PlayerEventsType.MODEL_UPDATED, this.onPlayerEvent)

      this.loadItem()
      this.ready = true
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Set player config and checks rubric support
   */
  async getContentConfig() {
    let contentConfig = this.setContentConfigDefaults({
      config: _.cloneDeep(this.contenConfig),
      itemType: this.itemType,
      defaults: {
        partialScoring: true,
      },
    })

    // rubric support type
    if (this.supportsRubric) {
      if (this.supportsMultiLevelRubric) {
        if (contentConfig.models) {
          for (const model of contentConfig.models) {
            model.rubricEnabled = true
          }
        }
      } else {
        // legacy
        contentConfig = await this.player.addRubricToConfig(contentConfig)
      }
    }

    return contentConfig
  }
  /**
   * Set player config and settings
   */
  async setPlayerConfigAndSettings() {
    this.player.configSettings = this.getConfigSettings(this.configSettings)
    try {
      this.player.defaultComplexRubricModel = this.defaultRubricConfig
      this.player.contentConfig = await this.getContentConfig()
    } catch (error) {
      EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error })
    }
  }

  /**
   * Return copy of config settings.
   */
  getConfigSettings(settings: any = {}): any {
    return _.cloneDeep(settings)
  }

  /**
   * determines if the item config has a complex rubric
   * how: complex-rubric model exists while old rubric model does not
   */
  isComplexRubric(config: {} = {}): boolean {
    let elementNameComplex = ''
    let elementNameOld = ''
    let modelFoundComplex = false
    let modelFoundOld = false

    if (typeof config?.elements == 'object') {
      for (const e in config.elements) {
        if (config.elements[e].search('/complex-rubric') > -1) {
          elementNameComplex = e
        } else if (config.elements[e].search('/rubric') > -1) {
          elementNameOld = e
        }
      }
    }

    // if we didn't find an element for complex rubric so
    // this is definitely not a multi-level rubric
    if (elementNameComplex == '') {
      return false
    }

    if (typeof config?.models == 'object') {
      for (const m of config.models) {
        if (m.element == elementNameComplex) {
          modelFoundComplex = true
        }
        if (m.element == elementNameOld) {
          modelFoundOld = true
        }
      }
    }

    return modelFoundComplex && !modelFoundOld
  }

  /**
   * checks if the item config is missing the html tag markup for the rubric
   */
  missingRubricMarkup(config: {} = {}): boolean {
    if (typeof config?.elements == 'object') {
      for (const e in config.elements) {
        if (config.elements[e].search('/rubric') > -1) {
          return config.markup.search(e) == -1
        }
      }
    }
    return false
  }

  /**
   * returns the html tag markup for the rubric model
   */
  generateRubricMarkup(config: {} = {}): string {
    let elementName = ''
    let rubricId = ''
    if (typeof config?.elements == 'object') {
      for (const e in config.elements) {
        if (config.elements[e].search('/rubric') > -1) {
          elementName = e
          break
        }
      }
    }
    if (elementName != '' && typeof config?.models == 'object') {
      for (const m of config.models) {
        if (m.element == elementName) {
          rubricId = m.id
        }
      }
    }
    if (elementName != '' && rubricId != '') {
      return `<${elementName} id="${rubricId}"></${elementName}>`
    }
    return ''
  }

  /**
   * Set defaults for content config
   */
  setContentConfigDefaults({
    config,
    itemType,
    defaults,
  }: {
    config: any
    itemType: string
    defaults: { [key: string]: any }
  }) {
    if (config) {
      config.models = config.models.map((o: any) => {
        o.partialScoring = defaults?.partialScoring || true
        return o
      })
    }
    return config
  }

  /**
   * Load new or existing item
   * @param itemTypeChange if item type set config instead of loading item
   */
  async loadItem(itemTypeChange: boolean = false) {
    this.loading = true
    if (this.isNew || itemTypeChange) {
      await this.setPlayerConfigAndSettings()
    } else {
      // preload settings (for custom labels) then load item
      this.player.configSettings = this.getConfigSettings(this.configs.getSettings(this.itemType))

      const { data } = await this.itemConfigsGetItemConfig({
        versionedId: this.item.remoteIdVersioned,
      })
      const config = _.cloneDeep(data.contentItem?.config)
      // set rubric model for supported items (fixes missing rubric markup in models)
      if (this.supportsRubric) {
        if (this.missingRubricMarkup(config)) {
          config.markup += this.generateRubricMarkup(config)
        }

        if (this.supportsMultiLevelRubric && this.isComplexRubric(config)) {
          if (config.models) {
            for (const model of config.models) {
              if (!model.rubrics) {
                model.rubricEnabled = true
              }
            }
          }
          this.player.contentConfig = config
        } else {
          const rubricModel = await this.player.addRubricToConfig(config)
          this.player.contentConfig = rubricModel
        }
      } else {
        this.player.contentConfig = config
      }
    }
    this.hydrateItem()
  }

  /**
   * Hydrate item with player config and settings
   */
  hydrateItem() {
    this.authorUpdateItem([
      {
        itemId: this.itemId,
        key: 'contentConfig',
        value: _.cloneDeep(this.player.contentConfig),
        save: false,
      },
      {
        itemId: this.itemId,
        key: 'configSettings',
        value: _.cloneDeep(this.player.configSettings),
        save: false,
      },
    ])

    if (this.supportsRubric) {
      this.isOldRubric(this.player.contentConfig)
    }
  }

  onItemConfirmAction({ cancel }: { cancel: boolean }) {
    this.itemExitConfirmAction = false
    if (!cancel) {
      this.itemData = this.itemTypeObject
    } else {
      this.changeItem(this.itemValue)
    }
  }

  /**
   * Check if Author made any changes
   * if Yes, then show the confirmation dialogue else changeItemType.
   */
  changeItemType(type: ItemType) {
    if (
      (this.authorHasChange && this.authorAutosave) ||
      (Object.keys(this.authorItemsMutated).length &&
        Object.keys(this.authorItemsMutated).includes(String(this.itemId)))
    ) {
      this.itemValue = type
      this.itemExitConfirmAction = true
    } else {
      this.changeItem(type)
    }
  }
  /**
   * Change item type and reload item
   * config and settings must also mutate
   */
  changeItem(type: ItemType) {
    if (!this.isEditMode && !this.item?.published) {
      this.loading = true
      const save = !this.isNew
      const mutations = [
        {
          itemId: this.itemId,
          key: 'itemType',
          value: type.id,
          save,
        },
        {
          itemId: this.itemId,
          key: 'contentConfig',
          value: _.cloneDeep(this.configs.getContentConfig(type.id)),
          save,
        },
        {
          itemId: this.itemId,
          key: 'configSettings',
          value: _.cloneDeep(this.configs.getSettings(type.id)),
          save,
        },
      ]

      if (this.isNew) this.authorUpdateItem(mutations)
      else this.$emit('change', mutations, true)
      this.loadItem(true)
    }
  }
  /**
   * Checking if CR model contains old rubric: Case for LIB to NIB migrated CR items
   */
  isOldRubric(config) {
    if (typeof config?.elements == 'object') {
      for (const e in config.elements) {
        if (config.elements[e].search('/rubric') > -1) {
          this.oldRubric = true
        }
      }
    }
  }
  /*
   * Adding rubricType if the model is old.
   */
  checkAndUpdateOldRubric() {
    if (this.oldRubric) {
      for (const model of this.player.contentConfig?.models) {
        if (model?.rubrics) {
          model.rubricType = 'simpleRubric'
          this.oldRubric = false
        }
      }
    }
  }
  /**
   * Player event handler/mux
   * @param {PlayerEvent} e  player event
   */
  onPlayerEvent(e: PlayerEvent) {
    switch (e.type) {
      case PlayerEventsType.CONTENT_LOADED:
        this.loading = false
        this.authorSetLoading(false)
        break
      case PlayerEventsType.MODEL_LOAED:
        this.loading = false
        this.authorSetLoading(false)
        this.$emit('loaded-item', true)
        break
      case PlayerEventsType.MODEL_UPDATED:
        if (this.supportsRubric) {
          this.checkAndUpdateOldRubric()
        }

        this.$emit('change', [
          {
            itemId: this.itemId,
            key: 'contentConfig',
            value: _.cloneDeep(this.player.contentConfig),
          },
          {
            itemId: this.itemId,
            key: 'configSettings',
            value: _.cloneDeep(this.player.configSettings),
          },
        ])
        break
    }
  }

  /**
   * Duplicate item handler
   */
  duplicateItem() {
    if (!this.authorItemLimit && !this.loading) {
      const passageAff = this.item?.passageAff
      const passage = this.authorInheritPassage ? this.authorPassageGetPassage(passageAff.passageRevId) : null
      const options = {
        contentConfig: this.contenConfig,
        configSettings: this.configSettings,
        passage: passage,
        meta: this.item?.meta || {},
        itemType: this.item.itemType,
      }
      this.$emit('duplicate-item', { options })
    }
  }

  /**
   * Delete item handler
   */
  async deleteItem() {
    if (!this.isSolitary && !this.loading) this.$emit('delete-item', { item: this.item })
  }

  /**
   * on mount call init player
   * add click event to build tab in player
   */
  mounted() {
    this.init()
    this.itemData = this.types.get(this.itemType) || this.types.default
  }
}

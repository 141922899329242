
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { StandardLabelMixin } from '@/mixins'

@Component({
  mixins: [StandardLabelMixin],
})
export default class StandardsList extends Vue {
  stdLabel!: (document: any, lastIndex?: number) => string
  @Prop({ required: true }) standardsData!: any[]
  @Prop({ required: true }) toggleStandard!: (guid: string, value) => void
  @Prop({ required: true }) isAlignedStandard!: (guid: string) => boolean
  @Prop({ required: false, default: false }) viewOnly!: boolean

  /**
   * Generate Standards Data
   * Generates a unique list of standards data based on `document_id`.
   * It filters out duplicate entries by keeping only the first occurrence
   * of each unique `document_id` in the `standardsData` array.
   *  @returns {any[]} An array of unique standards data.
   */

  get generateStandardsData(): any[] {
    return Object.values(
      this.standardsData.reduce<Record<string, any>>((acc, item) => {
        if (!acc[item.document_id]) {
          acc[item.document_id] = item
        }
        return acc
      }, {})
    ).sort((a, b) => {
      // Handle missing state/document.
      const stateA = a.state || ''
      const stateB = b.state || ''
      const docA = a.document || ''
      const docB = b.document || ''
      // First, sort by 'state'
      const stateComparison = stateA.localeCompare(stateB)
      if (stateComparison !== 0) {
        return stateComparison
      }
      // If 'state' is the same, sort by 'document'
      return docA.localeCompare(docB)
    })
  }
  /**
   * Get list of standards by document ID
   * @param document_id document_id
   */
  getStandardForDocument(document_id) {
    return this.standardsData.filter((item) => item.document_id === document_id)
  }
}

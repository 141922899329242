
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import * as VDialog from 'vuetify/es5/components/VDialog'
import XBtn from '@/components/xLib/XBtn'
import StandardsList from '@/components/standards/StandardsList.vue'

@Component({
  components: {
    ...VDialog,
    XBtn,
    StandardsList,
  },
})
export default class StandardListDialog extends Vue {
  @Prop({ required: true }) standardsData!: any[]
  @Prop({ required: false }) toggleStandard!: (guid: string) => void
  @Prop({ required: false }) isAlignedStandard!: (guid: string) => boolean
  @Prop({ default: false }) show!: boolean
  @Prop({ required: false, default: false }) viewOnly!: boolean

  closeDialog() {
    this.$emit('update:show', false)
  }
  showStannis() {
    this.$emit('show-stannis')
  }
}
